import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import { useIntl, FormattedMessage } from "react-intl";
import Img from "gatsby-image";
import LazyLoad from "react-lazyload";

import Layout from "../components/layout";
import Search from "../components/search";
import Seo from "../components/seo";
import OffersSlider from "../components/offers-slider";
import SpiritSlider from "../components/spirit-slider";
import HotelsSlider from "../components/hotels-slider";
import ServicesGrid from "../components/services-grid";
import ServiceModal from "../components/service-modal";
import Close from "../components/close";

const HomeTemplate = ({ data, pageContext: context }) => {
  const intl = useIntl();
  const siteUrl = data.site.siteMetadata.siteUrl;
  const allPages = data.allContentfulPage.nodes;
  const [modal, setModal] = useState("");

  function getSlug(category) {
    return allPages.find((page) => page.category === category).slug;
  }

  function toggleModal() {
    setModal(modal === "" ? " active" : "");
  }

  return (
    <Layout noButton>
      <Seo>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "@id": "${siteUrl}/${context.locale}/#organization",
              "url": "${siteUrl}/${context.locale}/",
              "name": "ACE Hôtel",
              "logo": "${siteUrl}/logo.png",
              "parentOrganization": {
                "@type": "Organization",
                "name": "Sighor"
              }
            }
          `}
        </script>
      </Seo>
      <section className="section section-white">
        <div className="hero">
          <div className="hero-container">
            <div className="hero-inner">
              <div className="hero-image">
                {data.contentfulHotel.hero_image && (
                  <Img
                    fluid={data.contentfulHotel.hero_image.fluid}
                    loading="eager"
                    alt="ACE Hôtel"
                  />
                )}
              </div>
              <div className="hero-content">
                <div className="hero-content-text">
                  <h1>{`${data.contentfulTraductions.main_title} ACE Hôtel`}</h1>
                  <p>{data.contentfulTraductions.subtitle}</p>
                </div>
                <div className="hero-content-form">
                  <Search />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-white overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 pr-xl-10">
              <h2 className="heading mt-xl-6">
                <FormattedMessage id="index.offers.heading" />
              </h2>
              <p className="d-none d-xl-block">
                <Link to={`/${context.locale}/${getSlug("Offres")}/`}>
                  <FormattedMessage id="index.offers.link" />
                </Link>
              </p>
            </div>
            <div className="col-8 col-sm-10 col-lg-12 col-xl-9">
              <LazyLoad>
                <OffersSlider />
                <div className="grid grid-offers d-none d-lg-grid">
                  {[
                    ...(data.contentfulHotel.offer
                      ? [data.contentfulHotel.offer]
                      : []),
                    ...data.allContentfulOffre.nodes,
                  ]
                    .filter(
                      (node, i, arr) =>
                        arr.findIndex((n) => n.slug === node.slug) === i
                    )
                    .slice(0, 3)
                    .map((node, i) => {
                      const {
                        url: iconUrl,
                        details: {
                          image: { width: iconWidth, height: iconHeight },
                        },
                      } = node.icon.file;
                      return (
                        <Link
                          className={`offer offer-${node.color}`}
                          to={`/${context.locale}/${getSlug("Offres")}/${
                            node.slug
                          }/`}
                          key={i}
                        >
                          <div className="offer-heading">
                            {node.discount && <p>{node.discount}</p>}
                            {node.icon && (
                              <img
                                src={iconUrl}
                                width={iconWidth}
                                height={iconHeight}
                                alt={node.title}
                              />
                            )}
                          </div>
                          <div className="offer-description">
                            <h3>{node.title}</h3>
                            <p>{node.short_description}</p>
                            <p className="link">
                              <FormattedMessage id="more" />
                            </p>
                          </div>
                        </Link>
                      );
                    })}
                </div>
              </LazyLoad>
            </div>
          </div>
          <div className="mt-8 d-flex justify-content-end d-xl-none">
            <p>
              <Link to={`/${context.locale}/${getSlug("Offres")}/`}>
                <FormattedMessage id="index.offers.link" />
              </Link>
            </p>
          </div>
        </div>
      </section>
      <section className="section section-clouds">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <h2 className="d-lg-none">{data.contentfulEsprit.main_title}</h2>
              {data.contentfulEsprit.gallery && (
                <div className="position-relative">
                  <LazyLoad>
                    <SpiritSlider data={data.contentfulEsprit} />
                    {data.contentfulEsprit.logo && (
                      <Img
                        className="logo-absolute"
                        fluid={data.contentfulEsprit.logo.fluid}
                        alt={data.contentfulEsprit.title}
                      />
                    )}
                  </LazyLoad>
                </div>
              )}
            </div>
            <div className="col-lg-6 offset-lg-1">
              <h2 className="d-none d-lg-block mt-6">
                {data.contentfulEsprit.main_title}
              </h2>
              <p className="text-blue-dark mt-8 mt-lg-6">
                {data.contentfulEsprit.short_description}
              </p>
              {data.contentfulEsprit.featured && (
                <div className="services">
                  {data.contentfulEsprit.featured.map((node, i) => {
                    const {
                      url,
                      details: {
                        image: { width, height },
                      },
                    } = node.file;

                    return (
                      <div className="services-item" key={i}>
                        <ServiceModal node={node}>
                          <img
                            src={url}
                            width={width}
                            height={height}
                            alt={node.title}
                          />
                          <p>
                            <span>{node.title}</span>
                          </p>
                        </ServiceModal>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="d-flex">
                <p className="mb-0">
                  <Link
                    className="link"
                    to={`/${context.locale}/${getSlug("Esprits")}/${
                      data.contentfulEsprit.slug
                    }/`}
                  >
                    <FormattedMessage id="more" />
                  </Link>
                </p>
                <p className="ml-10 mt-0">
                  <button className="link" onClick={toggleModal}>
                    <FormattedMessage id="index.services.button" />
                  </button>
                </p>
              </div>
              <div className={`modal modal-services${modal}`}>
                <div className="modal-content">
                  <button className="modal-close" onClick={toggleModal}>
                    <Close />
                  </button>
                  <div className="modal-scroll">
                    <div className="container">
                      <ServicesGrid />
                    </div>
                  </div>
                </div>
                <button
                  className="modal-backdrop"
                  aria-label={intl.formatMessage({ id: "close" })}
                  onClick={toggleModal}
                ></button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-blue overflow-hidden">
        <div className="container">
          <h2>
            <FormattedMessage id="index.hotels.heading" />
          </h2>
          <p className="lead mt-4 mt-md-0">
            {data.allContentfulHotel.totalCount + " "}
            <FormattedMessage id="index.hotels.paragraph" />
          </p>
          <div className="row mt-6 mt-md-10">
            <div className="col-8 col-sm-10 col-lg-12 position-static">
              <LazyLoad>
                <HotelsSlider />
              </LazyLoad>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default HomeTemplate;

export const query = graphql`
  query ($id: String!, $locale: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulHotel(
      contentful_id: { eq: "7Ghq3MGGxup1gZhcPpUnH" }
      node_locale: { eq: $locale }
    ) {
      seo_title
      meta_description
      hero_image {
        file {
          url
        }
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      offer {
        title
        slug
        color
        short_description
        type
        discount
        icon {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
    contentfulTraductions(node_locale: { eq: $locale }) {
      main_title
      subtitle
    }
    allContentfulOffre(
      filter: {
        active: { ne: false }
        type: { in: ["Offre permanente", "Offre ponctuelle"] }
        node_locale: { eq: $locale }
      }
      sort: { fields: updatedAt, order: DESC }
      limit: 3
    ) {
      nodes {
        title
        slug
        color
        short_description
        type
        discount
        icon {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
    contentfulEsprit(
      contentful_id: { eq: "7espJ6LdiBAZKMMNoQBmgv" }
      node_locale: { eq: $locale }
    ) {
      title
      main_title
      short_description
      slug
      logo {
        fluid(maxWidth: 100, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      featured {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
        title
        description
      }
      icon_gallery {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
        title
        description
      }
      gallery {
        fluid(maxHeight: 610, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
      }
    }
    contentfulComparatifTableau {
      content {
        title
        icon {
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
        simplement_bien
        simplement_bien_content
        travel
        travel_content
      }
    }
    allContentfulHotel(
      filter: { slug: { ne: "enseigne" }, node_locale: { eq: $locale } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        title
        slug
        thumbnail {
          fluid(maxHeight: 320, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      totalCount
    }
  }
`;
